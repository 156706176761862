import React, { useState, useMemo } from 'react';
import './NewsItem.css';
import { Link } from 'react-router-dom';

interface SeriesItem {
  id: number;
  name: string;
  label?: string;
  parent: number | null;
}


interface Image {
  url: string;
  description: string;
}

interface NewsItemProps {
  id: number;
  sender: {
    id: number;
    type: string;
  };
  type: string;
  title: string;
  excerpt: string;
  main: string;
  images: Image[];
  category: string;
  tags: string[];
  published: boolean;
  publishLocation: string;
  parentId: null | number;
  seriesType: string;
  seriesData?: SeriesItem[]; // <-- add this if you're passing it in
  order: number;
  likes: number;
  dislikes: number;
  seo?: {
    title?: string;
    description?: string;
    keywords?: string[];
    image?: string;
  };
  slug: string;
  createdAt: string;
  updatedAt: string;
}

const getTimeAgo = (dateString: string): string => {
  const now = new Date();
  const past = new Date(dateString);
  const seconds = Math.floor((now.getTime() - past.getTime()) / 1000);

  if (seconds < 60) return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) return `${minutes} min${minutes !== 1 ? 's' : ''} ago`;
  const hours = Math.floor(minutes / 60);
  if (hours < 24) return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  const days = Math.floor(hours / 24);
  if (days < 30) return `${days} day${days !== 1 ? 's' : ''} ago`;
  const months = Math.floor(days / 30);
  if (months < 12) return `${months} month${months !== 1 ? 's' : ''} ago`;
  const years = Math.floor(months / 12);
  return `${years} year${years !== 1 ? 's' : ''} ago`;
};


const NewsItem: React.FC<NewsItemProps> = ({
  title,
  excerpt,
  type,
  seriesType,
  slug,
  main,
  images,
  category,
  tags,
  publishLocation,
  published,
  createdAt,
  seriesData = []
}) => {
  const [votes, setVotes] = useState(0);

  const handleUpvote = () => setVotes(votes + 1);
  const handleDownvote = () => setVotes(votes - 1);

  const buildSeriesPath = (data: SeriesItem[]): string => {
    if (!data.length) return '';
  
    const pathParts: string[] = [];
    const map = new Map(data.map((item) => [item.id, item]));
  
    let current: SeriesItem | undefined = data[data.length - 1]; // Deepest item
  
    while (current) {
      pathParts.unshift(current.name);
      current = current.parent !== null ? map.get(current.parent) : undefined;
    }
  
    return pathParts.join('/');
  };
  
  
  const slugPath = useMemo(() => {
    if (!slug) return '/news';
  
    if (seriesData.length > 0) {
      const seriesPath = buildSeriesPath(seriesData);
      if (seriesPath) {
        return `/${type}/${seriesPath}/${slug}`;
      }
    }
  
    switch (type) {
      case 'article':
        return `/news/${slug}`;
      case 'tutorial':
        return `/tutorial/${slug}`;
      case 'doc':
      case 'documentation':
        return `/documentation/${slug}`;
      default:
        return `/news/${slug}`;
    }
  }, [slug, type, seriesData]);
  
  

  return (
    <div className="news-item business-item">
      <div className="news-item-inner business-item-inner">
        {images?.length > 0 && (
          <div
            className="news-item-images business-item-inner-image"
            style={{ backgroundImage: `url("${images[0].url}")` }}
          />
        )}

        <div className="tool-item-inner-details-container news-item-details">
          <h2>
            <Link to={slugPath}>
              {title}
            </Link>
          </h2>

          <p className="news-author">
            <span className="news-author-avatar" />
            <span className="news-author-name">Business Bloc</span>
          </p>

          <p className="news-excerpt">{excerpt}</p>

          <div className="news-item-tabs">
            <div className="news-tab-column-left">
              <div className="vote-section">
                <button className="upvote" onClick={handleUpvote}>
                  <i className="fas fa-arrow-up"></i>
                </button>
                <span className="vote-count">{votes}</span>
                <button className="downvote" onClick={handleDownvote}>
                  <i className="fas fa-arrow-down"></i>
                </button>
              </div>
            </div>

            <div className="news-tab-column-right">
              <div className="news-tab">
                <i className="fas fa-layer-group"></i>
                <span>{category}</span>
              </div>
              <div className="news-tab">
                <i className="fas fa-tags"></i>
                <span>{tags.length} tag{tags.length !== 1 ? 's' : ''}</span>
              </div>
              <div className="news-tab">
                <i className={`fas ${published ? 'fa-check-circle' : 'fa-times-circle'}`}></i>
                <span>{published ? 'Published' : 'Unpublished'}</span>
              </div>
              <div className="news-tab">
                <i className="fas fa-globe"></i>
                <span>{publishLocation == "news_center" ? "Global" : 'Internal'}</span>
              </div>
              <div className="news-tab">
                <i className="fas fa-clock"></i>
                <span>{getTimeAgo(createdAt)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsItem;
