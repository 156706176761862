import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import PageHeader from '../PageHeader/PageHeader';
import heroImage from '../../assets/images/about/welcome-to-business-bloc.webp';
import { Link } from 'react-router-dom';

interface ArticleData {
  title: string;
  type: string;
  description: string;
  content: string;
  image: string;
  metaTitle: string;
  metaDescription: string;
  tags?: string[];
  category?: string;
  slug: string;
  seo?: any;
  views?: number;
  likes?: number;
  dislikes?: number;
  seriesType?: string;
  seriesData?: any;
  order?: number;
  parentId?: number;
}


const Article: React.FC = () => {
  const { slug } = useParams();
  const [articleData, setArticleData] = useState<ArticleData | null>(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  // Extract slug and handle series data from location
  const pathSegments = location.pathname.split('/').filter(Boolean);

  // Optional: Handle default behavior if no slug is available
  const slugFromPath = pathSegments.length > 0 ? pathSegments[pathSegments.length - 1] : null;


  useEffect(() => {

    const fetchArticleData = async () => {
      const targetSlug = slug || slugFromPath;
  
      if (targetSlug) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BLOC_SERVER_URL}/content/retrieve/${targetSlug}`
          );
          const data = response.data;
          window.scrollTo({ top: 0, behavior: 'smooth' });

          if (data && data.title) {
            const formattedData: ArticleData = {
              title: data.title,
              type: data.type,
              description: data.excerpt || '',
              content: data.main || '',
              image: data?.images?.hero || heroImage,
              metaTitle: data?.seo?.metaTitle || data.title,
              metaDescription: data?.seo?.metaDescription || data.excerpt || '',
  
              // NEW fields
              tags: data.tags || [],
              category: data.category || '',
              slug: data.slug,
              seo: data.seo || {},
              views: data.views || 0,
              likes: data.likes || 0,
              dislikes: data.dislikes || 0,
              seriesType: data.seriesType || null,
              seriesData: data.seriesData || null,
              order: data.order || null,
              parentId: data.parentId || null,
            };
  
            setArticleData(formattedData);
          } else {
            setArticleData(null);
          }
        } catch (error) {
          console.error('Error fetching article:', error);
          setArticleData(null);
        } finally {
          setLoading(false);
        }
      } else {
        setArticleData(null);
        setLoading(false);
      }
    };
  
    fetchArticleData();
  }, [slug, slugFromPath]);  

  if (loading) {
    return <p style={{ padding: '2rem' }}>Loading article...</p>;
  }

  if (!articleData) {
    return <p style={{ padding: '2rem' }}>Article not found.</p>;
  }

  return (
    <div className="Article-page">
      <Helmet>
        <title>{articleData.metaTitle}</title>
        <meta name="description" content={articleData.metaDescription} />
        <meta property="og:title" content={articleData.metaTitle} />
        <meta property="og:description" content={articleData.metaDescription} />
        <meta property="og:image" content={articleData.image} />
        <meta property="og:type" content="article" />
      </Helmet>

      <PageHeader 
        pageTitle={articleData.title}
        pageImage={articleData.image}
        pageDescription={articleData.description}
        template="seo-article"
        pageAvailableObjectForUse={articleData}
      />
      <section className="Page">
        <div className="Page-content">
          <div className="Page-content-100">
            <div className="Page-content-inner">
              <article className="Article-content">
                <div
                  className="Article-body"
                  dangerouslySetInnerHTML={{ __html: articleData.content }}
                />
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Article;
