import React from "react";
import "./Footer.css";

const Footer: React.FC = () => {
  // Generate random order for the "Trending business tools" list
  const tools = [
    { name: "Website Maintenance", slug: "website-maintenance" },
    { name: "Task Manager", slug: "task-manager" },
    { name: "AI Website Generation", slug: "ai-website-generation" },
    { name: "Agents (Add-On)", slug: "user-agents" },
  ];
  
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Mission Statement */}
        <div className="footer-column">
          <h3><i className="fa fa-flag"></i> <span>Our Mission</span></h3>
          <p>
            We empower businesses to own and optimize their digital assets,
            from websites to logos, with cutting-edge tools and expert support.
          </p>
        </div>

        {/* Contact Information */}
        <div className="footer-column">
          <h3><i className="fa fa-bullhorn"></i> <span>Contact Us</span></h3>
          <p><a href="mailto:support@businessbloc.com"><i className="fa fa-envelope"></i> support@businessbloc.com</a></p>
          <p><a href="tel:7808519085"><i className="fa fa-phone"></i> 780-851-9085</a></p>
          <p><i className="fa fa-clock"></i> Available: 24/7</p>
        </div>

        {/* Popular Pages */}
        <div className="footer-column">
          <h3><i className="fa fa-file"></i>  <span>Popular Pages</span></h3>
          <ul>
            <li>
              <a href="/about"><i className="fa fa-info-circle"></i> About</a>
            </li>
            <li>
              <a href="/news"><i className="fa fa-newspaper"></i>News</a>
            </li>
            <li>
              <a href="/referral-program"><i className="fa fa-handshake"></i>Referral Program</a>
            </li>
          </ul>
        </div>

        {/* Trending Tools */}
        <div className="footer-column">
          <h3><i className="fa fa-fire"></i>  <span>Trending Business Tools</span></h3>
          <ul>
            {tools.map((tool, index) => (
              <li key={index}>
                <a href={`/business-tools/${tool.slug}`} className="tool-link">
                  {tool.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; Business Bloc 2025</p>
      </div>
    </footer>
  );
};

export default Footer;
