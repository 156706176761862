import React, { useState } from 'react';
import axios from 'axios';
import { JSDOM } from 'jsdom';
import DOMPurify from 'dompurify';
import heroImage from '../../assets/images/home/business-hero-highlight-4.png';
import heroImage2 from '../../assets/images/home/business-hero-highlight-3.png'; // Add more images as needed
import buildingBlocBuildingBg from '../../assets/images/home/building-bloc-building-bg.png'; // Add more images as needed

import validator from 'validator';
import { Formik, Form, Field, ErrorMessage, FormikHelpers, FormikState } from "formik";
import * as Yup from "yup";
import { useNavigate, Link } from "react-router-dom";
import './Hero.css'

type FormValues = {
  business_name: string,
  business_email: string,
  business_password: string,
}

const Hero: React.FC<{ businessAuth: any, appLoading: any }> = ({ businessAuth, appLoading }) => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [disabled, setDisabled] = useState(false);

  const initialValues: FormValues = {
    business_name: '',
    business_email: '',
    business_password: ''
  }

  const [currentDate, setCurrentDate] = useState<string>(() => {
    // Initialize with the current date in user's locale
    return new Date().toLocaleDateString();
  });

  const validationSchema = Yup.object().shape({
    business_name: 
      Yup.string()
      .min(2, "Your business name must be at least 2 characters.")
      .max(50, "Your businss name must be a maximum of 50 characters.")
      .matches(/^[\w ]*[^\W_][\w ]*$/, "Your business name may only contain letters and numbers.")
      .required("You must provide a business name that is between 2-50 characters."),
    business_email: 
      Yup.string()
      .email("You must provide an email address such as \"example@gmail.com\"")
      .min(5, "Your email address must be a minimum of 5 characters.")
      .max(60, "Your email address must be a maximum of 60 characters.")
      .required("You must provide an email address that is between 5-60 characters."),
    business_password: Yup.string()
      .min(8, "Your password must be a minimum of 8 characters.")
      .max(35, "Your password must be a maximum of 35 characters.")
      .matches(/[a-zA-Z]/, 'Your password must contain supported language letters.')
      .matches(/[@$!%*#?&]+/, "Your password must be 8-35 characters and contain at least one number and special character.")
      .matches(/\d+/, "Your password must be 8-35 characters and contain at least one number and special character.")
      .required("Your password may only be between 8-35 characters and contain at least one number and special character."),
  });

  
  const onSubmit = (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    setDisabled(true)
    axios.post(process.env.REACT_APP_BLOC_SERVER_URL + '/business/create', 
    { 
      values 
    }, 
    {
      withCredentials: true,
    }
    ).then((successAccountCreated) => {
      //alert(JSON.stringify(successAccountCreated))
      if(successAccountCreated.status == 200 && successAccountCreated.data.errorMessage == undefined){
        localStorage.setItem("business_bloc_token", successAccountCreated.data.token)   
        window.location.href = "/checkout";
      } else {
        setDisabled(false)
        setErrorMessage(successAccountCreated.data.errorMessage )
      }
    }).catch((e) => { 
      //alert(e)
      setDisabled(false)
      setErrorMessage("Unable to connect to server. Please retry in five minutes.")
    })
  } 

  const slides = [
    { image: heroImage, text: 'Restaurants' },
    { image: heroImage2, text: 'Construction' },
  ];
  

  return (
    <>
    { appLoading == false ?
    <section className="Hero">
      { /*       <div className="Hero-Main-Section"  style={{ backgroundImage: `linear-gradient(rgba(87, 4, 134, 0.9), rgba(98, 0, 134, 0.88)), url(${heroImage})` }}> */ }
      <div className="Hero-Main-Section Sunset-Edition" style={{ backgroundImage: `linear-gradient(rgba(87, 4, 134, 0.9), rgba(98, 0, 134, 0.88)), url()` }}>

      {/* SUN / MOON / BUILDING ANIMATION */}
      {/*<div className="sun"><span>Good morning</span></div>
      <div className="moon"><span>Good evening</span></div>
      <div className="building">
        <img src={buildingBlocBuildingBg} alt="Building silhouette" />
      </div>*/}
      
      
      
      <div className="Hero-Featured-Media">
        <div className="hero-featured-media">
          <div className="slides">
          {slides.map((slide, index) => (
            <div className="hero-slide" key={index}>
              <img src={slide.image} alt={`Slide ${index}`} />
              <span>{slide.text}</span>
            </div>
          ))}
        </div>
        </div>
      </div>
        <div className="Hero-content">
          <h1>Create your free website<br></br><span className="Main-Heading-Subtitle">in less than 2 minutes.</span></h1>
          <h2>Host when ready for $15 CAD/monthly<br/><small><i className="fa fa-server"></i> Website design and web hosting included</small></h2>
          <Link to="/create-business-website">Get started <i className="fa fa-chevron-right"></i></Link>
        </div>
      </div>
      { businessAuth.status == false ?
      <Formik
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          initialValues={initialValues}
      >
        <Form className="Hero-Register-Business">
        <i className="fas fa-search"></i>
          <div className="Hero-Search-Content">
            <span className="Dynamic-Business-Listing-Count">Join over 500+ businesses</span>
            <h2>Get started in 30 seconds</h2>
            { errorMessage != "" ?
            <div className="Hero-Register-Business-Error-Message">{errorMessage}</div>
            : "" }
            <div className="Field-Container">
              <ErrorMessage name="business_name" component="span" />
              <Field type="text" placeholder="Business name" name="business_name" />
            </div>
            <div className="Field-Container">
              <ErrorMessage name="business_email" component="span" />
              <Field type="text" placeholder="Email" name="business_email"/>
            </div>
            <div className="Field-Container">
              <ErrorMessage name="business_password" component="span" />          
              <Field type="password" placeholder="Password" name="business_password"/>
            </div>
            <button type="submit" disabled={disabled}>Register account</button>
          </div>
        </Form>
      </Formik>
     : businessAuth.rank == 'registered' ?
      <div className="Hero-Checkout-Business">
        <div className="Hero-Checkout-Business-Inner">
          <i className="fa-solid fa-exclamation-circle"></i>
          <h2>One more step required</h2>
          <p>Before you access all of the benefits of Business Bloc, you must select a pricing plan.</p>
          <Link to="/checkout">View plans</Link>
        </div>
      </div>
      : 
      <div className="Hero-Checkout-Business Whats-New-Today">
        <div className="Hero-Checkout-Business-Inner Whats-New-Today-Inner">
          <h2><i className="fa fa-newspaper"></i> What's new today</h2>
          <p>No news today for {currentDate}</p>
        </div>
      </div>
     } 
    </section>
    : 
    <>
      <section className="Hero-Loading">
        <div className="Hero-Main-Section">
        <div className="Hero-Featured-Media">
        <div className="hero-featured-media">
          <div className="slides">
          {slides.map((slide, index) => (
            <div className="hero-slide" key={index}>
              <img src={slide.image} alt={`Slide ${index}`} />
              <span>{slide.text}</span>
            </div>
          ))}
        </div>
        </div>
      </div>
          <div className="Hero-content">

          </div>
        </div>
        <div className="Hero-Checkout-Business">
          <div className="Hero-Checkout-Business-Inner">
            
          </div>
        </div>
      </section>
    </>
    }
  </>
  );
};

export default Hero;
