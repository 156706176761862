import React, { useState } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { Link } from 'react-router-dom';
import './Header.css'
import logo from '../../assets/images/business-bloc-logo.png';
import { useNavigate } from "react-router-dom";
interface Params {
  business_id: string;
}

const Header: React.FC<{ businessAuth: any }> = ({ businessAuth }) => {
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const toggleMenu = () => {
    setIsMobileOpen(!isMobileOpen);
  };
  let currentAuthBusinessId = businessAuth.entity.id

  const handleMenuItemClick = () => {
    setIsMobileOpen(false); // Close the menu when a menu item is clicked
  };

  const params: Params = {
    business_id: currentAuthBusinessId
  }

  const clearCookie = (cookieName: any) => {
    document.cookie = `${cookieName}=; Max-Age=0; path=/; SameSite=Lax;`;
  };

  const Logout = async() => {
    try {
      await axios.post(process.env.REACT_APP_BLOC_SERVER_URL + '/business/logout', {
        params,         
      }, {
        withCredentials: true // This tells axios to include cookies
      }).then((logoutDetails: any) => {
        if (logoutDetails.status === 200) {
          clearCookie('accessToken');
          window.location.href = '/'; // Redirect after logout
        }
      })    
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <header className="Header">
      <div className="Header-logo">
        <Link to="/">
          <img src={logo} alt="Business Bloc" />
          <span>Business<br/> Bloc</span>
        </Link>
      </div>
      { businessAuth && businessAuth.status == true ?
        <>
          { /* MOBILE OFF SCREEN HEADER */ }
          {isMobileOpen && <div className="overlay" onClick={toggleMenu}></div>}
          <div className={`mobile-menu ${isMobileOpen ? 'open' : ''}`}>
            <button className="close-button" onClick={toggleMenu}>
              &times;
            </button>
            <div className="logo">
              <Link to="/">
                <img src={logo} alt="Business Bloc" />
                <span>Business<br/> Bloc</span>
              </Link>
            </div>
            <ul className="menu-list">

              <li>
                <Link to="/business-tools" onClick={handleMenuItemClick}><i className="fa fa-wrench"></i> Business Tools</Link>
              </li>
              <li>
                <Link to="/" onClick={handleMenuItemClick}><i className="fa-solid fa-paintbrush"></i> Free Website Design</Link>
              </li>

              { businessAuth.rank != 'registered' || businessAuth.bloc_services.length > 0 ?
                  // Business has purchased a plan
                  <li>
                    <Link to="/dashboard" onClick={handleMenuItemClick}><i className="fa-solid fa-cog"></i> Business Dashboard</Link>
                  </li>
                  : 
                  <li>
                    <Link to="/checkout" onClick={handleMenuItemClick}><i className="fa-solid fa-shield"></i> Upgrade business plan</Link>
                  </li>                
                  }


            </ul>
          </div>
        { /* MAIN HEADER */ }
        <div className="Header-mobile-menu" onClick={toggleMenu}>
          <i className="fa-solid fa-bars"></i>
        </div>
        <nav className="Header-nav">
          <ul>
            {/*<li>*/}
              {/*<Link to="/business-tools"><i className="fa-solid fa-cube"></i> Tools</Link>*/}
              {/*<ul>
                <li><a href="/business-tools/ai-website-generation"> AI Website Generation</a></li>
                <li><a href="/business-tools/business-dashboard">Business Dashboard</a></li>
                <li><a href="/business-tools/website-maintenace">Website Maintenace</a></li>
                <li><a href="/business-tools/website-hosting">Website Hosting</a></li>
                <li><a href="/business-tools/custom-website-design">Custom Website Design</a></li>
                <li><a href="/business-tools/seo-article-generation">SEO article generation</a></li>
              </ul>*/}
            {/*</li>*/}
            <li>
              <Link to="/news"><i className="fa-solid fa-newspaper"></i> News</Link>
            </li>
            <li>
              <Link to="/business-tools"><i className="fa-solid fa-wrench"></i> Tools</Link>
            </li>
            <li>
              <Link to="/directory"><i className="fa-solid fa-list-alt"></i> Directory</Link>
            </li>
            {/*<li>
              <Link to="/referral-program"><i className="fa-solid fa-usd"></i> Referral</Link>
            </li>*/}
            <li>
              <Link to="/pricing"><i className="fa-solid fa-list"></i> Pricing</Link>
            </li>
            <div className="Auth-container">
              <li className="Logged-In">
                <div className="Business-Profile-Picture">
                  <div className="Business-Profile-Picture-Inner">
                    <img src="" className=""/>
                  </div>
                </div>
                <div className="Manage-Business-Options">
                  <a>{businessAuth.email}</a>
                  { businessAuth.rank != 'registered' || businessAuth.bloc_services.length > 0 ?
                  // Business has purchased a plan
                  <div className="Manage-Business-Button">
                    <a href={process.env.REACT_APP_BLOC_DASHBOARD_URL}>
                      <span className="Manage-Business-Menu"><i className="fa fa-cog"></i>Business dashboard</span>
                    </a>
                    <i onClick={Logout} className="fa fa-sign-out"></i>
                  </div>
                  : 
                  // Business has registered, but not purchased a plan
                  <div className="Manage-Business-Button">
                    <Link to="/checkout">
                      <span className="Manage-Business-Menu"><i className="fa fa-shield"></i> Upgrade business plan</span>
                    </Link>
                    <i onClick={Logout} className="fa fa-sign-out"></i>
                  </div>                  
                  }
                </div>
              </li>
            </div>
          </ul>
        </nav>
        </>
      : 
        <>
          { /* MOBILE OFF SCREEN HEADER */ }
          {isMobileOpen && <div className="overlay" onClick={toggleMenu}></div>}
          <div className={`mobile-menu ${isMobileOpen ? 'open' : ''}`}>
            <button className="close-button" onClick={toggleMenu}>
              &times;
            </button>
            <div className="logo">
              <Link to="/">
                <img src={logo} alt="Business Bloc" />
                <span>Business<br/> Bloc</span>
              </Link>
            </div>
            <ul className="menu-list">
              <li>
                <Link to="/news"><i className="fa-solid fa-newspaper"></i> News</Link>
              </li>
              <li>
                <Link to="/business-tools" onClick={handleMenuItemClick}><i className="fa fa-wrench"></i> Business Tools</Link>
              </li>
              <span className="sub-menu-list-heading">Trending tools</span>
              <li className="sub-menu-list-item">
                <Link to="/business-tools/website-maintenance" onClick={handleMenuItemClick}><i className="fa fa-cogs"></i> Website Maintenance</Link>
              </li>
              <li className="sub-menu-list-item">
                <Link to="/business-tools/ai-website-generation" onClick={handleMenuItemClick}><i className="fa-solid fa-paintbrush"></i> Free Website Design</Link>
              </li>
              <li>
                <Link to="/directory" onClick={handleMenuItemClick}><i className="fa-solid fa-list-alt"></i> Business Directory</Link>
              </li>
              <li>
                <Link to="/get-started" onClick={handleMenuItemClick}><i className="fa fa-building"></i> <span>Get started</span></Link>
              </li>
              <li>
                <Link to="/login" onClick={handleMenuItemClick}><i className="fa fa-user"></i> Login </Link>
              </li>
            </ul>
          </div>
          { /* MAIN HEADER */ }
          <div className="Header-mobile-menu" onClick={toggleMenu}>
            <i className="fa-solid fa-bars"></i>
          </div>
          <nav className="Header-nav">
            <ul>
              {/*<li>
                <Link to="/business-tools"><i className="fa-solid fa-wrench"></i> Tools</Link>
                <ul>
                  <li><a href="/business-tools/ai-website-generation">AI Website Generation</a></li>
                  <li><a href="/business-tools/business-dashboard">Business Dashboard</a></li>
                  <li><a href="/business-tools/website-maintenace">Website Maintenace</a></li>
                  <li><a href="/business-tools/website-hosting">Website Hosting</a></li>
                  <li><a href="/business-tools/custom-website-design">Custom Website Design</a></li>
                  <li><a href="/business-tools/seo-article-generation">SEO article generation</a></li>
                </ul>
                </li>*/}
              <li>
                <Link to="/news"><i className="fa-solid fa-newspaper"></i> News</Link>
              </li>
              <li>
                <Link to="/business-tools"><i className="fa-solid fa-wrench"></i> Tools</Link>
              </li>
              <li>
                <Link to="/directory"><i className="fa-solid fa-list-alt"></i> Directory</Link>
              </li>
              {/*<li>
                <Link to="/referral-program"><i className="fa-solid fa-usd"></i> Referral</Link>
              </li>*/}
              <li>
                <Link to="/pricing"><i className="fa-solid fa-list"></i> Pricing</Link>
              </li>
              <div className="Auth-container">
                <li className="Get-Started-Button">
                  <Link to="/get-started"><i className="fa fa-building"></i> <span>Get started</span></Link>
                </li>
                <li className="Login-Button">
                  <Link to="/login"><i className="fa fa-user"></i> Login </Link>
                </li>
              </div>
            </ul>
          </nav>
        </>
      }
    </header>
  );
};

export default Header;
