import React, { useState } from 'react';
import PageHeader from '../PageHeader/PageHeader'
import { Helmet } from 'react-helmet-async';
import './News.css'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, FormikHelpers, FormikState } from "formik";
import businessDirectoryMain from '../../assets/images/tools/tools.png';
import NewsItem from './NewsItem';

const News: React.FC = () => {
  const articlesPerPage = 12;

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredArticles, setFilteredArticles] = useState<any>([]);
  const [originalArticles, setOriginalArticles] = useState<any>([]);
  const [articleCategories, setArticleCategories] = useState<any>([]);
  const [availableSeries, setAvailableSeries] = useState<any>([]);

  const [selectedCategory, setSelectedCategory] = useState('');
  const [loadPageAssets, setLoadPageAssets] = useState(false);

  // Function to retrieve articles from the backend
  async function findArticles() {
    await axios.get(process.env.REACT_APP_BLOC_SERVER_URL + '/content/retrieve-news')
      .then((response) => {
        const articles = response.data;
        if (articles.length > 0) {
          setOriginalArticles(articles);
          setFilteredArticles(articles);
          // Extract categories from articles
          const categoriesSet = new Set();
          articles.forEach((article: any) => {
            if (article.category) {
              categoriesSet.add(article.category);
            }
          });

          const categoriesArray = Array.from(categoriesSet).map((name: any) => ({
            name,
            slug: name.toLowerCase().replace(/\s+/g, '-'),
          }));

          setArticleCategories(categoriesArray);

          // Grab series: In your findArticles function
          const seriesMap = new Map();

          articles.forEach((article: any) => {
            if (article.seriesData && Array.isArray(article.seriesData)) {
              article.seriesData.forEach((series: any) => {
                if (!seriesMap.has(series.name)) {
                  seriesMap.set(series.name, series);
                }
              });
            }
          });
          const seriesArray = Array.from(seriesMap.values());
          setAvailableSeries(seriesArray); // New state
        }
      }).catch((e) => {
        console.log(e)
        alert(e)
      });
  }

  // Trigger article retrieval when component mounts
  React.useEffect(() => {
    findArticles().then(() => {
      setLoadPageAssets(true);
    });
  }, []);

  // Scroll to top helper function
  const scrollToTop = () => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 10);
  };

  // Search functionality to filter articles
  const handleSearch = () => {
    const term = searchTerm.toLowerCase().trim();

    let filtered = originalArticles.filter((article: any) =>
      article.title.toLowerCase().includes(term)
    );
    

    if (selectedCategory) {
      filtered = filtered.filter((article: any) =>
        article.category.toLowerCase() === selectedCategory
      );
    }

    setFilteredArticles(filtered);
    setSelectedCategory('');
    setCurrentPage(1);
    scrollToTop();
  };

  // Pagination logic
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = filteredArticles.slice(indexOfFirstArticle, indexOfLastArticle);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    scrollToTop();
  };

  // Handle category click for filtering
  const handleCategoryClick = (categorySlug: string) => {
    if (categorySlug !== "clear") {
      // Filter based on selected category
      let filtered = originalArticles.filter((article: any) => 
        article.category.toLowerCase() === categorySlug
      );

      setSelectedCategory(categorySlug);
      setFilteredArticles(filtered);
    } else {
      setSelectedCategory('');
      setFilteredArticles(originalArticles);
    }
  };

  return (
    <>
      <div className="Directory-page Tool-directory-page">
        <Helmet>
          <title>Business News - Business Bloc</title>
          <meta name="description" content="Discover a variety of free and premium business tools designed to streamline and enhance your business operations." />
          <meta name="keywords" content="business tools, free business tools, paid business tools, operations tools, business operations, productivity tools, Business Bloc, business software" />
          <meta property="og:title" content="Business Tools - Business Bloc" />
          <meta property="og:description" content="Explore Business Bloc’s collection of free and paid tools to help improve and optimize your business operations." />
          <meta property="og:image" content={businessDirectoryMain} />
          <meta property="og:type" content="website" />
        </Helmet>
        <section className="Page-Header Business-Directory" style={{ backgroundImage: `linear-gradient(rgba(87, 4, 134, 0.9), rgba(98, 0, 134, 0.88)), url(${businessDirectoryMain}), url(${businessDirectoryMain})` }}>
          <div className="Page-Header-Main-Section-Full-Width">
            <div className="Page-Header-content-full-width">
              <div className="Page-Header-content-100">
                <h1>News</h1>
                <h2>Bloc News & Tutorials: Everything You Need to Succeed Online</h2>
                <div className="directory-business-search-container">
                  <input  
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search for an article, tutorial or doc" 
                    type="text"
                  />
                  <button onClick={handleSearch}>Search</button>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <section className="Page">
          <div className="Page-content">
            <div className="article-list-items business-list-items">

              {currentArticles.map((article: any, index: number) => (
                <NewsItem key={index} {...article} />
              ))}

              {Math.ceil(filteredArticles.length / articlesPerPage) > 1 && (
                <ul className="pagination">
                  {Array.from({ length: Math.ceil(filteredArticles.length / articlesPerPage) }, (_, index) => (
                    <li
                      key={index}
                      onClick={() => paginate(index + 1)}
                      className={currentPage === index + 1 ? 'active' : ''}
                    >
                      {index + 1}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <div className="Tool-filter-sidebar">
              <h2>Categories</h2>
              <div className="Tool-filter Page-content-inner">
                <ul>
                  {articleCategories.map((category: any) => (
                    <li key={category.name}>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          handleCategoryClick(category.slug);
                        }}
                      >
                        {category.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default News;


interface BusinessToolProps {
  category: string;
  iconClass: string;
}




const BusinessTool: React.FC<any> = ({ category, iconClass, handleCategoryClick  }) => {

  
  const handleFilter = (event: React.MouseEvent) => {
    event.preventDefault();
    console.log('handleCategoryClick:', handleCategoryClick);
    handleCategoryClick(category.toLowerCase());
  };

  return (
    <li>
      <a href="#" onClick={handleFilter}>
        <i className={`fa ${iconClass}`}></i>
        {category}
      </a>
    </li>
  );
};